const $hamburger = $(".js-hamburger");
const $gnav = $(".js-gnav");

export function hamburger() {
  openHamburger();
}

function openHamburger() {
  $hamburger.on("click", () => {
    $(event.currentTarget).toggleClass("is-active");
    $gnav.toggleClass("is-active");
  });
}
