export function smooth() {
  $('a[href^="#"]').click(function() {
    var speed = 500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top - $(".l-header").outerHeight();
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    console.log("test");
    return false;
  });
}
