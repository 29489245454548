import { hamburger } from "./modules/hamburger";
import { smooth } from "./modules/smooth";
import { swiper } from "./modules/swiper";
import { hashPageScroll } from "./modules/hashPageScroll";

swiper();
smooth();

$(() => {
  hamburger();
});

$(window).on("load", () => {
  hashPageScroll();
});
